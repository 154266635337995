/* TODO: Dynamically resize font size */
/* Make subtitle bigger */

.primary-title {
  font-size: 8rem;
  white-space: nowrap;
  font-size: clamp(3rem, 5vw, 8rem);
  font-weight: 500;
  padding: 4px;
}

#icons {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.icon {
  height: 32px;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(16px, 2vw, 4rem);
  color: white;
}

.btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: clamp(22px, 2vw, 4rem);
  color: white;
  cursor: pointer;
}

#scroll-r:active {
  background-color: rgba(117, 117, 117, 0.2);
}

#scroll-p {
  color: black;
  background-color: #fff;
}

#scroll-p:active {
  background-color: rgb(211, 211, 211);
}

#second-text {
  margin: 0 -10px 0 -10px;
}

#hero-image {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url(/img/background/lab.jpg);
  min-height: 100vh;
  background-size: cover;
}

@media screen and (min-width: 800px) {
  .btn {
    height: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .icon2 {
    padding: 20px;
    height: 72px;
  }
  #icons-desktop {
    display: flex;
    justify-content: space-around;
  }
  .icon {
    padding: 20px;
    height: 72px;
  }
  #icons2-desktop {
    display: flex;
    justify-content: space-around;
  }

  #desktop-i {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  #icons2 {
    display: none;
  }
  #icons {
    display: none;
  }

  .btn {
    width: 300px;
    height: 60px;
    font-size: 20px;
    margin-left: 60px;
    margin-right: 60px;
  }
}

@media screen and (min-width: 1600px) {
  .btn {
    width: 300px;
  }
}

@media screen and (min-width: 1920px) {
  .btn {
    width: 400px;
    height: 74px;
    font-size: 28px;
  }

  #second-text {
    line-height: 44px;
  }
}

@media screen and (max-width: 780px) {
  .btn {
    /* top: 21%; */
    font-size: 12px;
  }

  .btn,
  p {
    padding: 5px;
  }
}

@media screen and (max-width: 280px) {
  .btn {
    white-space: normal;
    font-size: 10px;
  }
}

#scopus {
  margin: 0;
}
